@font-face {
  font-family: Quicksand;
  src: url(./Font/Quicksand.ttf) format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Quicksand, sans-serif;
  -webkit-tap-highlight-color: transparent;
}

body {
  background-color: #f0f0f0;
  background-image: url(./Img/background.svg);
  position: relative;
}

:root {
  font-size: clamp(14px, 11px + 0.3333vw, 16px);
}

::selection {
  color: #fff;
  background-color: #000;
}

input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus {
  outline: 2px solid #000;
}

#root:empty::after {
  content: '';
  position: absolute;
  top: 9rem;
  left: 50%;
  translate: -50%;
  width: 75px;
  height: 75px;
  border: 8px solid #dadada;
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 1s ease infinite;
}