.order-details-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 2rem;
    margin-top: 6rem;
    padding: 0 1.5rem 1.5rem 1.5rem;
}

.order-details-title-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.orders-back-icon {
    position: absolute;
    left: 0;
    top: 50%;
    translate: 0 -50%;
    font-size: 1.25rem;
    color: #eaeaea;
    background-color: #000;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.25s ease, transform 0.25s ease;
}

.orders-back-icon:hover {
    background-color: #353535;
}

.orders-back-icon:active {
    transform: scale(0.95);
}

.order-details-container {
    width: min(60rem, 100%);
    padding: 1rem;
    background-color: #fff;
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 1fr);
    align-items: start;
    justify-items: center;
    gap: 1.5rem;
}

.order-details-container:has(h2) {
    height: calc(100svh - 12.15rem);
    align-items: center;
}

.order-details-container>h3 {
    grid-column: span 2;
}

.order-items-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.order-status-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 0.5rem;
}

.order-status-container.disable {
    pointer-events: none;
}

.order-status-container p {
    font-size: 1.1rem;
    font-weight: 500;
}

.order-status-container form {
    display: none;
}

.ordered-products-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.ordered-product-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 2px dashed #000;
}

.ordered-product-container img {
    grid-row: span 2;
    width: 6rem;
}

.ordered-product-container p {
    font-weight: 550;
}

.order-total-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;
}

.order-total-container h3 {
    font-weight: 550;
    font-size: clamp(1rem, 7vw - 11.2px, 1.2rem);
}

.order-total-container h3:last-child {
    margin-left: auto;
}

.order-total-container .price-details-icon {
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.order-total-container .price-details-icon p {
    position: absolute;
    bottom: 100%;
    left: 50%;
    translate: -50% 0;
    z-index: 1;
    background-color: #ededed;
    border-radius: 6.5px;
    box-shadow: 0 0 30px #c0c0c0;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 150%;
    white-space: nowrap;
    padding: 0.35rem 0.65rem;
    transform-origin: bottom center;
    transform: scale(0);
    transition: transform 0.25s ease;
    pointer-events: none;
}

.order-total-container .price-details-icon:hover p {
    transform: scale(1);
}

.delivery-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.delivery-address-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: #eaeaea;
    border-radius: 10px;
    padding: 1rem;
}

.delivery-address-div h4 {
    font-size: 1.1rem;
}

.delivery-address-div p {
    line-height: 150%;
}

.delivery-address-div a {
    text-decoration: none;
    color: #000;
}

.delivery-address-div a:hover {
    text-decoration: underline;
}

.delivery-div form {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 1.5rem;
}

.delivery-div form.disable {
    pointer-events: none;
}

.delivery-div form input {
    font-weight: 500;
    text-transform: uppercase;
    padding: 0.5rem 1.5rem;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 10px;
    outline: none;
}

.delivery-div form input[type='text'] {
    display: none;
}

.delivery-div form input::-webkit-datetime-edit-day-field:focus,
.delivery-div form input::-webkit-datetime-edit-month-field:focus,
.delivery-div form input::-webkit-datetime-edit-year-field:focus {
    background-color: black;
    color: white;
}

.delivery-div form button {
    width: 11.25rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: #eaeaea;
    background-color: #000;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.25s ease, transform 0.25s ease;
}

.delivery-div form button:hover {
    background-color: #353535;
}

.delivery-div form button:active {
    transform: scale(0.95);
}

@media screen and (max-width: 750px) {
    .order-details-container>h3 {
        grid-column: span 1;
    }
}

@media screen and (max-width: 600px) {

    .order-details-wrapper {
        margin-top: 0;
    }

    .delivery-div form input {
        width: calc(50% - 0.75rem);
        min-width: 10rem;
        padding: 0.5rem 1rem;
    }

    .delivery-div form button {
        width: calc(50% - 0.75rem);
    }
}

@media (pointer: coarse) {

    .orders-back-icon:hover {
        background-color: #000;
    }

    .orders-back-icon:active {
        background-color: #353535;
        transform: scale(0.95);
    }

    .delivery-address-div a:hover {
        text-decoration: none;
    }

    .delivery-address-div a:active {
        text-decoration: underline;
    }

    .delivery-div form button:hover {
        background-color: #000;
    }

    .delivery-div form button:active {
        background-color: #353535;
        transform: scale(0.95);
    }
}