.products-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 2rem;
    padding: 6rem 2rem 2rem 2rem;
}

.products-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10.5rem, 1fr));
    gap: 2rem;
}

.add-product {
    height: 14.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    border: 2px solid #d0d0d0;
    border-radius: 10px;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: box-shadow 0.25s ease;
}

.add-product:hover {
    box-shadow: 0 0 10px 5px #d0d0d0;
}

.product-dialog {
    align-self: start;
    margin-block: auto;
    scale: 0;
    transition: scale 0.25s ease;
}

.product-dialog.show {
    scale: 1;
}

.product-form {
    width: min(35rem, 95vw);
    padding: 1.5rem 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 2rem;
    background-color: #ededed;
    box-shadow: 0 0 30px #959595;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
}

.product-form.disable {
    pointer-events: none;
}

.product-form>h2 {
    grid-column: 1 / -1;
    text-align: center;
}

.dialog-close-icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 3.25rem;
    width: 3.25rem;
    background-color: #000;
    font-size: 2rem;
    color: #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 20px;
    z-index: 1;
    cursor: pointer;
    transition: box-shadow 0.25s ease;
}

.dialog-close-icon:hover {
    box-shadow: -1px 1px 10px #000
}

.product-form input:read-only {
    display: none;
}

.product-form input,
.product-form textarea {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    background-color: #fff;
    border-radius: 10px;
    border: none;
}

.product-form input[type="number"]::-webkit-inner-spin-button {
    appearance: none;
}

.product-form textarea {
    resize: none;
    grid-column: 1 / -1;
}

.stock-div {
    height: 2.75rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid black;
    border-radius: 10px;
    overflow: hidden;
}

.stock-div .stock-icon-span {
    height: 100%;
    width: 2.75rem;
    background-color: #000;
    color: #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.25s ease;
}

.stock-div .stock-icon-span:hover {
    background-color: #353535;
}

.stock-div .stock-icon {
    pointer-events: none;
}

.stock-div input {
    width: calc(100% - 5.5rem);
    padding-inline: 0.25rem;
    text-align: center;
    outline: none;
}

.product-form .image-drop {
    grid-column: 1 / -1;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
}

.product-form .image-drop .image-div {
    width: max(17%, 4.5rem);
    aspect-ratio: 1 / 1;
    border: 2px solid #d0d0d0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-block: 0.25rem;
    position: relative;
}

.product-form .image-drop .image-div img {
    height: 100%;
}

.product-form .image-drop .image-div span {
    position: absolute;
    top: -0.6rem;
    left: -0.6rem;
    width: 1.45rem;
    height: 1.45rem;
    border-radius: 50%;
    background-color: #000;
    color: #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.95rem;
    cursor: pointer;
    transition: background-color 0.25s ease, transform 0.25s ease;
}

.product-form .image-drop .image-div span:hover {
    background-color: #353535;
}

.product-form .image-drop .image-div span:active {
    transform: scale(0.95);
}

.product-form .image-drop label {
    width: max(17%, 4.5rem);
    aspect-ratio: 1 / 1;
    border: 2px solid #d0d0d0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: #aaa;
    cursor: pointer;
    position: relative;
    transition: transform 0.25s ease;
}

.product-form .image-drop label:active {
    transform: scale(0.95);
}

.product-form .image-drop label::after {
    content: '.webp';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0.25rem;
    font-size: 0.85rem;
}

.product-form .image-drop label input {
    display: block;
    height: 1px;
    width: 1px;
    padding: 0;
    opacity: 0;
}

.product-form button.new {
    grid-column: 1 / -1;
}

.product-form button {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: #eaeaea;
    background-color: #000;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.25s ease, transform 0.25s ease;
}

.product-form button:hover {
    background-color: #353535;
}

.product-form button:active {
    transform: scale(0.95);
}

.product-form button.delete {
    background-color: crimson;
}

.product-form button.delete:hover {
    background-color: red;
}

@media screen and (max-width: 600px) {
    .products-wrapper {
        padding: 0 1.5rem 2rem 1.5rem;
    }
}

@media screen and (max-width: 450px) {
    .product-form {
        padding: 1.5rem;
        gap: 1.5rem;
    }

    .stock-div .stock-icon-span {
        width: 2.25rem;
    }

    .stock-div input {
        width: calc(100% - 4.5rem);
    }
}

@media (pointer: coarse) {
    .dialog-close-icon:hover {
        box-shadow: none
    }

    .dialog-close-icon:active {
        box-shadow: -1px 1px 10px #000
    }

    .stock-div .stock-icon-span:hover {
        background-color: #000;
    }

    .stock-div .stock-icon-span:active {
        background-color: #353535;
    }

    .product-form .image-drop .image-div span:hover {
        background-color: #000;
    }

    .product-form .image-drop .image-div span:active {
        background-color: #353535;
        transform: scale(0.95);
    }

    .product-form button:hover {
        background-color: #000;
    }

    .product-form button:active {
        background-color: #353535;
        transform: scale(0.95);
    }

    .product-form button.delete:hover {
        background-color: crimson;
    }

    .product-form button.delete:active {
        background-color: red;
        transform: scale(0.95);
    }
}