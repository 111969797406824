.navigation {
    height: 5rem;
    width: 100%;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    z-index: 999;
    padding: 1rem 2rem;
    backdrop-filter: blur(30px);
    background-color: #f0f0f0b4
}

.logo-link {
    width: 5rem;
    flex-shrink: 0;
}

.pages {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: clamp(1.25rem, 10.5vw - 56px, 2rem);
}

.pages a {
    font-size: 1.05rem;
    font-weight: 600;
    text-decoration: none;
    color: #000;
    transition: transform 0.25s ease;
}

.pages a:active {
    transform: scale(0.95);
}

.pages a.active {
    color: darksalmon;
}

.navigation button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 10px;
    background-color: black;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.25s ease, transform 0.25s ease;
}

.navigation button:hover {
    background-color: #353535;
}

.navigation button:active {
    transform: scale(0.95);
}

@media screen and (max-width: 600px) {

    .wrapper-nav {
        position: relative;
        z-index: 1;
        margin-bottom: 1rem;
    }

    .navigation {
        position: relative;
        z-index: unset;
        background-color: #f0f0f0;
        backdrop-filter: none;
        gap: 2rem;
    }

    .pages {
        background-color: inherit;
        position: absolute;
        top: -20rem;
        left: 0;
        z-index: -1;
        width: 100%;
        flex-direction: column;
        gap: 1rem;
        border-top: 2px solid #dadada;
        padding: 1rem;
        box-shadow: 0 50px 30px #eaeaea;
        transition: top 0.5s ease;
    }

    .pages.open {
        top: 100%;
    }

    .pages a {
        padding: 1rem 0;
        width: 100%;
        text-align: center;
        border-radius: 10px;
    }

    .pages a.active {
        background-color: darksalmon;
        color: #f0f0f0;
    }

    .menu-span {
        cursor: pointer;
        margin-right: auto;
        transition: transform 0.25s ease;
    }

    .menu-icon {
        font-size: 2rem;
        pointer-events: none;
    }

    .menu-span:hover {
        color: #353535;
    }

    .menu-span:active {
        transform: scale(0.95);
    }
}

@media (pointer: coarse) {
    .navigation button:hover {
        background-color: black;
    }

    .navigation button:active {
        background-color: #353535;
        transform: scale(0.95);
    }

    .menu-span:hover {
        color: #000;
    }

    .menu-span:active {
        color: #353535;
        transform: scale(0.95);
    }
}