.promos-card-container {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    justify-content: space-evenly;
    justify-items: center;
    gap: 1.5rem;
    padding: 6rem 2rem 2rem 2rem;
}

.promos-card-container h1 {
    grid-column: 1 / -1;
    justify-self: center;
    margin-bottom: 1rem;
}

.promos-card-container:has(>h2) {
    grid-template-columns: 1fr;
    row-gap: 2rem;
}

.add-new {
    width: min(100%, 30rem);
    min-height: 6rem;
    height: 100%;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.25rem;
    cursor: pointer;
    transition: background-color 0.25s ease, transform 0.25s ease;
}

.promotion-card {
    width: min(100%, 30rem);
    min-height: 6rem;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #dadada;
    padding: 0.8rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    align-content: space-between;
    cursor: pointer;
    transition: background-color 0.25s ease, transform 0.25s ease;
}

.promotion-card h2 {
    grid-column: 1 / -1;
    justify-self: center;
}

.promotion-card p {
    font-weight: 500;
}

.promotion-card p:last-child {
    justify-self: end;
}

.add-new:hover,
.promotion-card:hover {
    background-color: #dadada;
}

.add-new:active,
.promotion-card:active {
    transform: scale(0.95);
}

.promos-form-wrapper {
    width: min(25rem, 100dvw - 3rem);
    padding: 1.5rem 2rem;
    background-color: #ededed;
    box-shadow: 0 0 30px #959595;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    scale: 0;
    transition: scale 0.25s ease;
}

.promos-form-wrapper.show {
    scale: 1;
}

.promos-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 2rem;
}

.promos-form.disable {
    pointer-events: none;
}

.promos-close {
    position: absolute;
    top: 0;
    right: 0;
    height: 3.25rem;
    width: 3.25rem;
    background-color: #000;
    font-size: 2rem;
    color: #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 20px;
    z-index: 1;
    cursor: pointer;
    transition: box-shadow 0.25s ease;
}

.promos-close:hover {
    box-shadow: -1px 1px 10px #000;
}

.promos-form input[name='id'] {
    display: none;
}

.promos-form input {
    width: 100%;
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    font-weight: 500;
    background-color: #fff;
    border-radius: 10px;
    border: none;
}

.promos-form input[type="number"]::-webkit-inner-spin-button {
    appearance: none;
}

.promos-form input[type="text"],
.promos-form input[type="date"] {
    text-transform: uppercase;
}

.promos-form input[type="text"]::placeholder {
    text-transform: none;
}

.promos-form input[type="date"]::-webkit-datetime-edit-day-field:focus,
.promos-form input[type="date"]::-webkit-datetime-edit-month-field:focus,
.promos-form input[type="date"]::-webkit-datetime-edit-year-field:focus {
    background-color: black;
    color: white;
}

.promos-form button {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: 500;
    color: #eaeaea;
    background-color: #000;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.25s ease, transform 0.25s ease;
}

.promos-form button:hover {
    background-color: #353535;
}

.promos-form button:active {
    transform: scale(0.95);
}

.delete-update-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
}

.delete-update-div button.delete {
    background-color: crimson;
}

.delete-update-div button.delete:hover {
    background-color: red;
}

@media screen and (max-width: 600px) {
    .promos-card-container {
        padding: 0 2rem 2rem 2rem;
    }
}

@media (pointer: coarse) {

    .add-new:hover,
    .promotion-card:hover {
        background-color: #fff;
    }

    .add-new:active,
    .promotion-card:active {
        background-color: #dadada;
        transform: scale(0.95);
    }

    .promos-close:hover {
        box-shadow: none;
    }

    .promos-close:active {
        box-shadow: -1px 1px 10px #000;
    }

    .promos-form button:hover {
        background-color: #000;
    }

    .promos-form button:active {
        background-color: #353535;
        transform: scale(0.95);
    }

    .delete-update-div button.delete:hover {
        background-color: crimson;
    }

    .delete-update-div button.delete:active {
        background-color: red;
        transform: scale(0.95);
    }
}