.orders-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 6rem 2rem 2rem 2rem;
}

.topbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.searchbox {
    position: relative;
}

.searchbox.disable {
    pointer-events: none;
}

.searchbox input {
    width: 15rem;
    font-size: 0.95rem;
    font-weight: 500;
    padding: 0.75rem 3rem 0.75rem 2rem;
    border-radius: 10px;
    border: none;
    text-transform: uppercase;
}

.hash-icon {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    font-size: 1.25rem;
    pointer-events: none;
}

.search-icon {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    font-size: 1.25rem;
    cursor: pointer;
    transition: color 0.25s ease, transform 0.25s ease;
}

.search-icon:hover {
    color: #353535;
    transform: scale(1.1);
}

.search-icon:active {
    transform: scale(0.95);
}

.orders-cards-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.orders-cards-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, auto);
    justify-content: space-between;
    gap: 1.5rem;
}

.orders-cards-container>h2 {
    grid-column: 1 / -1;
    text-align: center;
}

.order-card {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: subgrid;
    align-items: center;
    justify-items: center;
    padding: 0.5rem 1.5rem;
    background-color: #fff;
    border-radius: 10px;
    text-decoration: none;
    color: black;
    transition: transform 0.25s ease;
}

.order-card img {
    width: 4rem;
}

.order-card h4:last-of-type {
    justify-self: end;
    text-transform: capitalize;
}

.order-card:active {
    transform: scale(0.95);
}

@media screen and (max-width: 750px) {
    .orders-cards-container {
        grid-template-columns: repeat(3, 1fr);
    }

    .order-card {
        padding: 1rem;
        gap: 1rem;
    }

    .order-card h4:last-of-type {
        justify-self: center;
    }
}

@media screen and (max-width: 600px) {

    .orders-container {
        padding-top: 0;
    }

    .topbar {
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
    }

    .searchbox input {
        width: 100%;
    }

    .order-card img {
        grid-row: span 2;
    }

    .order-card h4:not(:last-of-type) {
        grid-column: span 2;
        justify-self: self-start;
    }

    .order-card p:first-of-type {
        white-space: nowrap;
    }
}