.home-container {
    width: 100%;
    min-height: 100svh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 2rem;
    padding: 6rem 2rem 2rem 2rem;
}

.insights-container {
    display: grid;
    grid-template-columns: minmax(15rem, 1fr) repeat(auto-fit, minmax(15rem, 1fr));
    gap: clamp(0.5rem, 28vw - 126px, 2rem);
}

.insights-container .insights-div {
    display: grid;
    grid-template-columns: minmax(4rem, 1fr) 1fr;
    place-items: center;
    column-gap: 1rem;
    padding: 1.5rem;
    border-radius: 10px;
    background-color: #fff;
    text-decoration: none;
    color: #000;
    transition: transform 0.25s ease;
}

.insights-container .insights-div:active {
    transform: scale(0.95);
}

.insights-container .insights-div span {
    grid-row: span 2;
    width: 100%;
    max-width: 5rem;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(1.5rem, 10.5vw - 21px, 2.25rem);
    color: #eaeaea;
    border-radius: 50%;
    background-color: black;
}

.insights-container .insights-div h2 {
    justify-self: start;
    font-size: clamp(1.1rem, 7vw - 14px, 1.5rem);
}

.insights-container .insights-div h3 {
    justify-self: start;
    font-size: clamp(0.9rem, 3.78vw - 2.52px, 1.17rem);
}

.chart-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
}

.linechart-container {
    height: 25rem;
    width: calc(70% - 1rem);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 10px;
    background-color: #fff;
}

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tooltip {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    font-weight: 500;
    outline: 1px solid rgb(204, 204, 204);
    white-space: nowrap;
}

.no-transactions {
    flex-grow: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: 600;
    border: 2px dashed #d0d0d0;
    border-radius: 10px;
}

.piechart-container {
    height: 25rem;
    width: calc(30% - 1rem);
    min-width: 20rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    border-radius: 10px;
    background-color: #fff;
}

.pie-filter label span {
    background-color: #fff;
}

.not-sold {
    margin-block: auto;
    height: 60%;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 600;
    border: 1rem solid black;
    border-radius: 50%;
}

@media screen and (max-width: 850px) {
    .chart-container {
        flex-direction: column;
    }

    .linechart-container {
        padding: 1rem;
        width: 100%;
    }

    .piechart-container {
        padding: 1rem;
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .home-container {
        padding: 0 2rem 2rem 2rem;
    }
}

@media screen and (max-width: 500px) {
    .insights-container {
        grid-template-columns: minmax(10rem, 1fr) minmax(10rem, 1fr);
    }

    .insights-container .insights-div {
        grid-template-columns: minmax(3rem, 1fr) 1fr;
        padding-inline: 0.5rem;
        column-gap: 0.25rem;
    }

    .insights-container .insights-div span {
        width: clamp(3rem, 28vw - 70px, 5rem);
    }

    .linechart-container {
        height: 20rem;
    }

}