.product-card {
    position: relative;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    border: 2px solid #d0d0d0;
    border-radius: 10px;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: box-shadow 0.25s ease;
}

.product-card:hover {
    box-shadow: 0 0 10px 5px #d0d0d0;
}

.card-img {
    width: 8rem;
    margin-bottom: 0.5rem;
    margin-top: 0.25rem;
    margin-inline: auto;
}

.card-img img {
    width: 100%;
}

.product-card h3 {
    text-align: center;
    margin-bottom: 0.5rem;
}

.product-card .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info p {
    font-size: 0.9rem;
}

.info p:first-child {
    font-weight: 600;
}

.review-rating {
    position: absolute;
    display: flex;
    padding: 0.1rem 0.2rem;
    right: 0.25rem;
    top: 0.25rem;
    gap: 0.25rem;
    border-radius: 6px;
    font-size: 0.85rem;
    font-weight: 600;
}

.review-rating .rating {
    display: flex;
    align-items: center;
    gap: 0.15rem;
    padding-right: 0.25rem;
    border-right: 1.5px solid #000;
}

.review-rating .rating .star-icon {
    font-size: 0.8rem;
}

.product-card-stock {
    position: absolute;
    padding: 0.25rem 1rem;
    left: -1rem;
    top: 0.25rem;
    border-radius: 6px;
    font-size: 0.85rem;
    font-weight: 500;
    background-color: #000;
    color: #eaeaea;
}

@media (pointer: coarse) {
    .product-card:hover {
        box-shadow: none;
    }

    .product-card:active {
        box-shadow: 0 0 10px 5px #d0d0d0;
    }
}