.calendarWrap {
    width: fit-content;
    position: relative;
}

.calendarWrap>p {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    width: fit-content;
    background-color: #fff;
    font-size: 0.95rem;
    font-weight: 500;
    padding: 0.75rem 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.25s ease;
}

.calendar-icon {
    font-size: 1.25rem;
}

.calendarWrap>p.focus {
    outline: 2px solid black;
}

.calendarWrap>p:active {
    transform: scale(0.95);
}

.calendarElement {
    position: absolute;
    right: 0;
    top: 3.25rem;
    outline: 1px solid #ccc;
    border-radius: 10px;
    z-index: 899;
    overflow: hidden;
}

.rdrDayToday .rdrDayNumber span:after {
    background-color: #000;
}

@media screen and (max-width: 600px) {

    .calendarWrap {
        align-self: center;
    }

    .calendarElement {
        right: 50%;
        transform: translateX(50%);
    }
}